import * as bootstrap from "bootstrap";
import SimpleBar from "simplebar";
import bootbox from "bootbox";
import "bootbox/dist/bootbox.locales.min.js";
import bs5 from "bs5-toast";
import * as custom from "./custom.js";

import Datepicker from "vanillajs-datepicker/Datepicker";
import DateRangePicker from "vanillajs-datepicker/DateRangePicker";
import th from "vanillajs-datepicker/locales/th";
Object.assign(Datepicker.locales, th);

window.$ = window.jQuery = window.jquery = $;
window.SimpleBar = SimpleBar;
window.bootbox = bootbox;
window.bootstrap = bootstrap;
window.bs5 = bs5;
window.func = custom;

window.Datepicker = Datepicker;
window.DateRangePicker = DateRangePicker;

const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
const popoverList = [...popoverTriggerList].map((popoverTriggerEl) => new bootstrap.Popover(popoverTriggerEl));

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
const tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));
